import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { PhoneInput, TextInput } from '@shared/components';
import { useForm } from '@shared/hooks';
import { ProfilSettingModel } from '@shared/models';
import { Avatar, Button, Form, Input, InputNumber, Select, Upload } from 'antd';
import { z } from 'zod';

const ProfileSettingForm = z.object({
  image: z.string({ required_error: 'Resim yüklenmelidir' }),
  name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Soyadı zorunlu alandır' }).min(1, 'Soyadı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('This is not a valid email'),
  phone: z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(1, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
});

export const ProfileSetting = () => {
  const form = useForm<ProfilSettingModel>(ProfileSettingForm, {});

  return (
    <div className="w-1/2">
      <Form layout="vertical">
        <Form.Item {...form.formItem('image')} label={<Avatar shape="square" size={64} icon={<UserOutlined />} />}>
          <Upload>
            <Button icon={<UploadOutlined />}>Profil fotoğrafı yükle</Button>
          </Upload>
        </Form.Item>

        <div className="grid grid-cols-2 gap-x-4">
          <TextInput formItem={form.formItem('name')} formInput={form.input('name')} label={'Ad'} />
          <TextInput formItem={form.formItem('surname')} formInput={form.input('surname')} label={'Soyad'} />
        </div>

        <PhoneInput form={form} />

        <TextInput formItem={form.formItem('email')} formInput={form.input('email')} label={'E-posta Adresi:'} maxLength={50} />

        <Form.Item>
          <Button type="primary" onClick={() => console.log(form)}>
            Değişiklikleri kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
