import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';

export const PasswordSetting = () => {
  return (
    <Form layout="vertical" className="w-1/3">
      <Form.Item label="E-posta">
        <Input prefix={<MailOutlined />} placeholder="example@example.com" />
      </Form.Item>

      <Form.Item>
        <Button type="primary">Sıfırmala bağlantısı gönder</Button>
      </Form.Item>
    </Form>
    /*
        <div className="grid gap-5">
            <div className="grid grid-cols-2">
                <div>
                    <label>E-posta Adresi</label>
                    <Input prefix={<MailOutlined/>} placeholder="example@example.com"/>
                </div>
            </div>
            <div className="gap-4">
                <Button type="primary">Sıfırmala bağlantısı gönder</Button>
            </div>
        </div>
        */
  );
};
