import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { Role } from '@shared/constants';

export const Dashboard = () => {
  //const [result, loading, getDashboard] = API.DASHBOARD.useFetch({ init: true })
  return <ComingSoon navigationText="Create Brand" navigationUrl="/create-brand" />;
};

/*
<div>
            <div className="grid grid-cols-3 gap-2">
                <Card hoverable
                    onClick={() => { navigate('/brands'); }}
                >
                    <Statistic
                        title="Brand"
                        value={result?.brandCount}
                        formatter={formatter}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<ArrowUpOutlined />}
                    />
                </Card>
                <Card hoverable
                    onClick={() => { navigate('/brands'); }}
                >
                    <Statistic
                        title="Brand Users"
                        value={result?.brandUserCount}
                        formatter={formatter}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<ArrowUpOutlined />}
                    />
                </Card>
            </div>
        </div>
*/

//TODO çoklu dil destegi
Dashboard.path = '/';
Dashboard.title = 'Kontrol Paneli';
Dashboard.group = 'flat';
Dashboard.roles = [Role.ADMIN];
Dashboard.icon = <DashboardOutlined size={20} />;
