import { ProfileOutlined } from '@ant-design/icons';
import { Product } from '@shared/models';
import * as API from '@shared/api';
import { Spin } from 'antd';
import { ProductCard } from '@admin/admin_components';
import { useEffect, useState } from 'react';
import { Role } from '@shared/constants';
import { Spinner } from '@shared/components';

export const Products = () => {
  const [result, loading, getProducts] = API.PRODUCT.useFetch({ init: true });
  const [defaultProducts, setDefaultProducts] = useState<Product[]>([
    {
      id: undefined,
      name: 'Learning Management System',
      active: false,
      description: 'Öğrenme yönetim sistemi deneme',
      baseProperties: {
        perBranch: 15,
      },
      type: 'LMS',
    },
    {
      id: undefined,
      name: 'Human Resources',
      active: false,
      description: 'İnsan kaynakları modülü',
      baseProperties: {
        perBranch: 30,
      },
      type: 'HR',
    },
    {
      id: undefined,
      name: 'Customer Relation Management',
      active: false,
      description: 'Müşteri yönetim modülü',
      baseProperties: {
        perBranch: 10,
      },
      type: 'CRM',
    },
  ]);
  useEffect(() => {
    if (result && result.data!.length > 0) {
      const { data } = result;
      const newArray = defaultProducts.map((product) => {
        const { type } = product;
        const foundProduct = data?.find((d) => d.type == type);
        if (foundProduct) {
          return foundProduct;
        } else {
          return product;
        }
      });
      const d = newArray.filter((i) => !!i) as Product[];
      setDefaultProducts(d);
    }
  }, [result]);

  return (
    <div className="grid grid-cols-3 gap-4">
      <Spinner loading={loading} />
      {defaultProducts?.map((p) => (
        <ProductCard
          key={p.id ? p.id : (Math.random() + 1).toString(36).substring(7)}
          productProp={p}
          onRefresh={() => {
            getProducts();
          }}
        />
      ))}
    </div>
  );
};

Products.path = 'products';
Products.title = 'Ürünler';
Products.group = 'flat';
Products.roles = [Role.ADMIN];
Products.icon = <ProfileOutlined size={20} />;
