import { Login } from "@shared/authenticator/Login";
import { AdminLayout } from "@shared/components";
import { useUserContext } from "@shared/contexts";
import { BrowserRouter } from "react-router-dom";
import * as _pages from './pages';
const pages = Object.entries(_pages);

export const AdminAppContent = ()=>{
    const { user } = useUserContext();
    return (
        <BrowserRouter>
            {user.jwt ? (
                <AdminLayout pages={pages} />
            ) : (
                <Login />
            )}
        </BrowserRouter>
    );
}