import { RadiusSettingOutlined } from "@ant-design/icons";
import { useBrandSearchParams } from "@shared/hooks/useBrandSearchParams";
import { Role } from "@shared/constants";

export const ManageProductInitial = () => {
  const {brand, setBrand} = useBrandSearchParams();

  return (
    <div>Ürünleri Yönet page {brand?.name}</div>
  )
}

ManageProductInitial.path = 'manage-product-initial';
ManageProductInitial.title = 'Ürünleri Yönet';
ManageProductInitial.group = 'selectedBrand';
ManageProductInitial.roles = [Role.ADMIN];
ManageProductInitial.contexts = ['brand'];
ManageProductInitial.icon = <RadiusSettingOutlined size={20}/>
