import { Product, EnableProduct } from '@shared/models';
import { List } from 'antd';
import { useEffect, useState } from 'react';
import { CreateProductCard } from './CreateProductCard';

type CreateBrandProductProps = {
  systemProducts: Product[];
  addBrandProduct: (productProps: EnableProduct[]) => void;
};
export const CreateBrandProduct = ({ systemProducts = [], addBrandProduct }: CreateBrandProductProps) => {

  const [receipt, setReceipt] = useState<EnableProduct[]>([]);
  useEffect(() => { addBrandProduct([...receipt]) }, [receipt]);

//TODO EnableProduct altında active bilgisine göre silme ve ekleme yapalabilir
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="grid grid-cols-2 col-span-2 gap-4 gap-y-8">
        {systemProducts.map((p) => (p.active ? <CreateProductCard key={p.id} product={p} onRemove={(enableProduct) => { 
          const i = receipt.findIndex((r) => {
            return r.productInitialId == enableProduct.productInitialId;
          });
          if(i!=-1){
            const removeRecipt = [...receipt];
            removeRecipt.splice(i,1);
            setReceipt([...removeRecipt]);
          }
        }} onAdd={(enableProduct) => {
          if (receipt.length == 0) {
            const newRecipt = [enableProduct];
            setReceipt([...newRecipt]);
          } else {
            const i = receipt.findIndex((r) => {
              return r.productInitialId == enableProduct.productInitialId;
            });
            if(i!=-1){
              const updateRecipt = [...receipt];
              updateRecipt[i] = enableProduct;
              setReceipt([...updateRecipt]);
            }else{
              const newRecipt = [...receipt, enableProduct];
              setReceipt([...newRecipt]);
            }
          }
        }} /> : <div key={p.id}></div>))}
      </div>

      <div className="">
        <List
          className="bg-white"
          footer={
            <div className="grid grid-cols-2 w-full">
              <label className="font-medium">KDV Oranı :</label>
              <span className="text-right">x USD / kullanıcı başına</span>
              <label className="font-medium">Genel Toplam :</label>
              <span className="text-right text-selected-color font-medium">...USD</span>
            </div>
          }
          bordered
          dataSource={receipt}

          renderItem={(item, index) => {

            const systemProduct = systemProducts.find((s) => s.id == item.productInitialId);
            return (
              <List.Item key={index}>
                <div className="grid grid-cols-2 w-full">
                  <label className="font-medium">{systemProduct?.type}:</label>
                  <span className="text-right">{item.price} USD / kullanıcı başına</span>
                  <label className="text-success">İndirim Oranı :</label>
                  <span className="text-right text-success">-%{item.discount}</span>
                </div>
              </List.Item>
            );
          }

          }
        />
      </div>
    </div>
  );
};
