import { CheckCircleOutlined } from "@ant-design/icons";
import { EnableProduct, Product } from "@shared/models";
import { Avatar, Card, Form, Input, InputNumber, Switch, Tag } from "antd";
import { valueType } from "antd/es/statistic/utils";
import { useEffect, useState } from "react";

type CreateProductCardProps = {
  product: Product;
  onRemove: (product: EnableProduct) => void;
  onAdd: (product: EnableProduct) => void;
};

const inputNumber = <T extends object>(obj: T, key: keyof T, setter: (obj: T) => void) => {
  return {
    value: obj[key] as number,
    onChange: (e) => {
      const _obj = { ...obj, [key]: e };
      setter(_obj);
    },
  };
};
//TODO ProductCard ile bu component benzer bunlar birleştirebiliriz
export const CreateProductCard = ({ product, onRemove, onAdd }: CreateProductCardProps) => {

  const [enableProduct, setEnableProduct] = useState<EnableProduct>({ active: false ,discount:0,price:0,basePrice:0});
  const colorMaps = {
    "LMS": ["bg-[#FFFBE6]", "border-[#FAAD14]"],
    "CRM": ["bg-[#F0F5FF]", "border-[#2F54EB]"],
    "HR": ["bg-[#FFF0F6]", "border-[#EB2F96]"],
  };
  useEffect(() => {
    if (product) {
      const d = {
        productInitialId: product.id,
        basePrice: product.baseProperties?.perBranch,
        price: product.baseProperties?.perBranch,
        discount: 0,
        properties: {},
      };
      setEnableProduct({
        ...enableProduct,
        ...d
      });
    }
  }, [product]);

  useEffect(() => {
    if (enableProduct) {
      const { basePrice, price, discount ,active} = enableProduct;
      if (basePrice == price) {
        if (discount != 0 && !discount) {
          const newEnableProduct = {
            ...enableProduct,
            discount: 0
          }
          setEnableProduct({
            ...newEnableProduct,
          });
          onAdd(newEnableProduct);
        }
      } else {
        const newDiscount = (100 - ((price! * 100) / basePrice!));
        if (newDiscount != discount) {
          const newEnableProduct = {
            ...enableProduct,
            discount: newDiscount
          }
          setEnableProduct({
            ...newEnableProduct,
          });
          onAdd(newEnableProduct);
        }

      }
    }
  }, [enableProduct]);

  return (
    <Card
      title={
        <Card.Meta className="flex items-center meta-avatar-padding" avatar={<Avatar size={40} shape="square" className={"border mr-4 " + colorMaps[product.type?.toString() ?? "CRM"].join(' ')} />} title={product.type} />
      }
      extra={
        <div className="flex gap-2 items-center">
          <Switch value={enableProduct.active} onChange={() => {
            const newEnableProduct = {
              ...enableProduct,
              active: !enableProduct.active
            };
            setEnableProduct({...newEnableProduct}); 
              if(newEnableProduct.active){
                onAdd(newEnableProduct);
              }else{
                onRemove(newEnableProduct);
              }
          }} />
        </div>
      }
    >
      <div className="grid grid-cols-2 gap-4  items-baseline ">
        {
          /*
          <div className="flex flex-row flex-wrap gap-y-2 col-span-2">
            <Tag icon={<CheckCircleOutlined />} color="success">
              Facebook
            </Tag>
            <Tag icon={<CheckCircleOutlined />} color="success">
              Facebook
            </Tag>
            <Tag icon={<CheckCircleOutlined />} color="success">
              Facebook
            </Tag>
            <Tag color="default">Facebook</Tag>
            <Tag color="default">Facebook</Tag>
          </div>
          */
        }


        <label>Öğrenci başına fiyat:</label>
        <Form.Item className="help-right" help={"%" + enableProduct.discount + " indirim"}>
          <InputNumber size="small" className="w-full" {...inputNumber(enableProduct,"price",setEnableProduct)}  />
        </Form.Item>
        <label>Enable:</label>
        <Form.Item className=" text-right" >
          {product.baseProperties?.perBranch} USD
        </Form.Item>

        <span className="col-span-2 text-dark-gray">{product.description}</span>
        <span className="col-span-2 text-success">Toplam % şu kadar indirim uygulandı.</span>
      </div>
    </Card>
  );
}