import { HistoryOutlined, SearchOutlined, TeamOutlined } from '@ant-design/icons';
import { ActionDropdown } from '@shared/components';
import { Role } from '@shared/constants';
import { Account, PaginationQuery } from '@shared/models';
import { Button, Input, InputRef, notification, Popconfirm, Switch, Table, TableColumnsType, TableColumnType } from 'antd';
import * as API from '@shared/api';
import { useEffect, useRef, useState } from 'react';

type AccountType = keyof Account;

export const Accounts = () => {
  const [accounts, loading, getAccounts] = API.ACCOUNT.getAccounts({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    if (paginationQuery) {
      getAccounts(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  const onActiveChange = async (record: Account, query) => {
    if (record.active) {
      const result = await API.ACCOUNT.disableAccount(record.id);
      result.ok && notification.success({ message: 'Kullanıcı pasif duruma alınmıştır' });
    } else {
      const result = await API.ACCOUNT.enableAccount(record.id);
      result.ok && notification.success({ message: 'Kullanıcı aktifleşmiştir' });
    }
    getAccounts(undefined, query);
  };

  const onResetPassword = async (record: Account) => {
    const result = await API.ACCOUNT.accountResetPassword(record.id);
    result.ok && notification.warning({ message: 'Şifre sıfırlama isteği gönderilmiştir' });
  };

  const getColumnSearchProps = (dataIndex: AccountType): TableColumnType<Account> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Filtrele"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className="block mb-2"
        />
        <div className="flex justify-around gap-3">
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small">
            Filtrele
          </Button>

          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            Kapat
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, fontSize: '120%' }} />,
  });

  const columns: TableColumnsType<Account> = [
    {
      title: 'Ad Soyad',
      key: 'name',
      align: 'center',
      render: ({ name, surname }) => `${name} ${surname}`,
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Kimlik No',
      key: 'nationalId',
      align: 'center',
      dataIndex: 'nationalId',
      ...getColumnSearchProps('nationalId'),
    },
    {
      title: 'Telefon',
      key: 'phone',
      align: 'center',
      render: ({ phone }) => `+${phone.code} ${phone.number}`,
    },
    {
      title: 'E-posta',
      key: 'email',
      align: 'center',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Aktif / Pasif',
      key: 'active',
      align: 'center',
      render: (_, record) => (
        <Popconfirm
          title={record.active ? 'Kullanıcıyı pasif yapmak istediğinize emin misiniz?' : 'Kullanıcıyı aktif yapmak istediğinize emin misiniz?'}
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => {
            onActiveChange(record, paginationQuery);
          }}
        >
          <Switch size="small" value={record.active} />
        </Popconfirm>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (record) => (
        <ActionDropdown
          items={[
            {
              key: 'reset-password',
              label: 'Şifre Sıfırlama İsteği Gönder',
              icon: <HistoryOutlined />,
              onClick: () => {
                onResetPassword(record);
              },
            },
          ]}
          data={record}
          isDelete={false}
          isEdit={false}
          placement="bottom"
        />
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey="id"
      columns={columns}
      dataSource={accounts?.data}
      pagination={{
        total: accounts?.count,
        pageSize: paginationQuery.limit,
        position: ['bottomCenter'],
        onShowSizeChange(current, size) {
          setPaginationQuery({ ...paginationQuery, limit: size, page: current });
        },
        pageSizeOptions: ['6', '10', '20', '50'],
      }}
      bordered
      onChange={(pagination, filters) => {
        const { current, pageSize } = pagination;
        const newQuery = {
          ...paginationQuery,
          page: current,
          limit: pageSize,
          ...filters,
        };
        Object.keys(newQuery).forEach((key) => {
          if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
            newQuery[key] = newQuery[key][0];
          }
        });
        setPaginationQuery(newQuery);
      }}
    />
  );
};

Accounts.path = 'accounts';
Accounts.title = 'Kullanıcılar';
Accounts.group = 'flat';
Accounts.roles = [Role.ADMIN];
Accounts.icon = <TeamOutlined size={20} />;
