import { CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, SortAscendingOutlined, TableOutlined } from '@ant-design/icons';
import { EducationLevel, PaginationQuery } from '@shared/models';
import { Button, Drawer, Form, Input, InputNumber, notification, Select, Table, Tag } from 'antd';
import * as API from '@shared/api';
import { useNavigate } from 'react-router-dom';
import { Role } from '@shared/constants';
import { EducationLevelSorting, EducationLevelsView } from '@shared/components';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from '@shared/hooks';

const UpdateLevelForm = z.object({
  name: z.string({ required_error: 'Kur adı zorunlu alandır' }).min(1, 'Kur adı boş olamaz'),
  durationInHours: z
    .union([z.number(), z.null()])
    .transform((value) => value ?? undefined)
    .refine((value) => value !== undefined, 'Toplam kur saati zorunlu alandır'),
});

export const EducationLevels = () => {
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 1000 });
  const [levels, loading, getLevels] = API.EDUCATION_LEVEL.SYSTEM.useFetchLevel({ init: false });
  const [theme, themeLoading, getThemes] = API.EDUCATION_LEVEL.SYSTEM.useFetchTheme({ init: true });
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState<EducationLevel>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const form = useForm<EducationLevel>(UpdateLevelForm, {});
  const [isScrolllingMod, setIsScrolllingMod] = useState<boolean>(false);
  const [levelMap, setLevelMap] = useState<{ [key: string]: EducationLevel[] }>();
  const navigate = useNavigate();

  useEffect(() => {
    getLevels(undefined, paginationQuery);
  }, [paginationQuery]);

  useEffect(() => {
    if (theme?.data) {
      const filter = theme.data.map((t) => {
        return { label: t.name!, value: t.id! };
      });
      setFilterOptions(filter as never[]);
    }
  }, [theme]);

  useEffect(() => {
    if (levels) {
      const _levelMap: { [key: string]: EducationLevel[] } = {};
      levels.data?.forEach((l) => {
        const name = l.theme?.type?.field?.name! + '/' + l.theme?.type?.name! + '/' + l.theme?.name!;
        if (_levelMap[name]) {
          _levelMap[name] = [..._levelMap[name]!, l];
        } else {
          _levelMap[name] = [l];
        }
      });
      setLevelMap(_levelMap);
    }
  }, [levels, theme]);

  useEffect(() => {
    if (selectedLevel) {
      form.setValue({ ...selectedLevel });
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedLevel]);

  const title = (
    <div className="flex justify-between">
      <div className="flex flex-row content-center items-center space-x-2">
        <span className="font-bold">Kurlar</span>
        <Input
          placeholder={'Seviye Adı'}
          value={paginationQuery ? paginationQuery['name'] : undefined}
          onChange={(e) => {
            const name = e.target.value;
            if (name) {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                name,
              });
            } else {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                name: undefined,
              });
            }
          }}
        />
        <div className="flex flex-row space-x-2">
          <Select
            placeholder={'Türe göre filtrele'}
            value={paginationQuery ? paginationQuery['theme'] : undefined}
            style={{ width: '100%' }}
            options={filterOptions}
            onChange={(e) => {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                theme: e,
              });
            }}
          />
          {paginationQuery['name'] || paginationQuery['theme'] ? (
            <div className="flex items-center">
              <Button type="text" size="small" icon={<MinusCircleOutlined />} onClick={() => setPaginationQuery({ page: 1, limit: 6 })}>
                Filtreyi Kaldır
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="space-x-2">
        <Button
          icon={<SortAscendingOutlined />}
          onClick={() => {
            setIsScrolllingMod(!isScrolllingMod);
          }}
        >
          Sıralama Modununa Geç
        </Button>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            navigate('/education-level-definition');
          }}
          type="dashed"
        >
          Yeni Kur Oluştur
        </Button>
      </div>
    </div>
  );

  const onUpdateSelectedLevel = async () => {
    const result = form.parse();

    if (result.success) {
      await API.EDUCATION_LEVEL.SYSTEM.updateLevel({ ...selectedLevel, name: result.data['name'], durationInHours: result.data['durationInHours'] });
      notification.success({ message: 'Güncelleme başarıyla gerçekleşti' });
      setPaginationQuery({ ...paginationQuery });
      onCloseDrawer();
    } else {
      form.setAllTouched();
    }
  };

  const onCloseDrawer = () => {
    setSelectedLevel(undefined);
    setIsDrawerOpen(false);
    form.reset();
  };

  const onOrderLevels = async (list: EducationLevel[]) => {
    const orderList = list.map((l) => {
      return {
        id: l.id,
        order: l.order,
      };
    });
    await API.EDUCATION_LEVEL.SYSTEM.orderLevel(orderList);
    getLevels();
  };

  return (
    <div>
      {!isScrolllingMod ? (
        <div>
          <Drawer
            title={selectedLevel?.theme?.type?.field?.name + ' / ' + selectedLevel?.theme?.type?.name + ' / ' + selectedLevel?.theme?.name + ' / ' + selectedLevel?.name}
            open={isDrawerOpen}
            onClose={onCloseDrawer}
            closable={true}
          >
            <Form layout="vertical">
              <Form.Item {...form.formItem('name')} label={'Kur Adı:'}>
                <Input {...form.input('name')} />
              </Form.Item>
              <Form.Item {...form.formItem('durationInHours')} label={'Toplam Ders Saati :'}>
                <InputNumber min={1} {...form.inputNumber('durationInHours')} />
              </Form.Item>

              <Button type="primary" className="mt-1" onClick={onUpdateSelectedLevel}>
                Güncelle
              </Button>
            </Form>
          </Drawer>

          <EducationLevelsView
            levels={levels ?? {}}
            pageSize={levels?.count}
            isLoading={loading}
            title={title}
            onEdit={(l) => {
              setSelectedLevel(l);
            }}
            onChangePagination={(page) => setPaginationQuery({ ...paginationQuery, page: page })}
          />
        </div>
      ) : (
        <div className="flex flex-col ">
          <div className="flex justify-between">
            <Button
              icon={<TableOutlined />}
              onClick={() => {
                setIsScrolllingMod(!isScrolllingMod);
              }}
            >
              Tablo Modununa Geç
            </Button>
          </div>
          <div className="flex flex-col m-2">
            {levelMap &&
              Object.keys(levelMap).map((l, index) => {
                return (
                  <div className="mt-4 space-y-1" key={index}>
                    <span className="text-lg font-semibold">{l}</span>
                    <div className="flex flex-row overflow-auto h-20 border border-solid">
                      <EducationLevelSorting levels={levelMap[l]} onOrder={onOrderLevels} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

EducationLevels.path = 'education-levels';
EducationLevels.title = 'Kurlar';
EducationLevels.group = 'definitions';
EducationLevels.roles = [Role.ADMIN];
