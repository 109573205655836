import { Role } from "@shared/constants";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as API from '@shared/api';
import { notification } from "antd";
import { ContentVersionNavigation } from "@shared/models";
import { ReviewLearningContentVersion } from "@shared/components";

export const AdminReviewLearningContentVersion = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [contentNavigation, setContentNavigation] = useState<ContentVersionNavigation>();
    const [isLoading, setIsLoading] = useState(true);
    const [versionId, setVersionId] = useState<string>();


    useEffect(() => {
        if (searchParams.size > 0) {
            const versionId = searchParams.get('versionId');
            if (versionId) {
                getFirstPage(versionId);
            }
        }
    }, [searchParams]);

    const getFirstPage = async (versionId: string) => {
        setVersionId(versionId);
        try {
            const result = await API.CURRICULUM.COURSE_VERSION.startFirstPage(versionId);
            const navigationData = result.data;
            setContentNavigation(navigationData);

        } catch (error) {
            notification.error({
                message: 'Hata',
                description: 'Hata olustu. Lütfen tekrar deneyiniz.'
            })
        } finally {
            setIsLoading(false);
        }
    }

    const prevPage = async () => {
        if (versionId && contentNavigation?.meta.hasPrev) {
            const result = await API.CURRICULUM.COURSE_VERSION.prevPage(versionId, contentNavigation?.meta.pageId!);
            setContentNavigation(result.data);
        }
    }

    const nextPage = async () => {
        if (versionId) {
            try {
                const result = await API.CURRICULUM.COURSE_VERSION.nextPage(versionId, contentNavigation?.meta.pageId!);
                if (result.ok) {
                    setContentNavigation(result.data);
                }
            } catch (error) {
                notification.warning({
                    message: 'Bilgilendirme',
                    description: 'Sonuncu sayfaya ulaştınız.'
                })
            }

        }
    }

    const goToPage = async (pageId: string) => {
        if (versionId) {
            const result = await API.CURRICULUM.COURSE_VERSION.goToPage(versionId, pageId);
            setContentNavigation(result.data);
        }
    }

    const rejectPage = async () => {
        if (versionId) {
            try {
                await API.CURRICULUM.COURSE_PAGE.reject(versionId, contentNavigation?.meta.unit.id!, contentNavigation?.meta.topic.id!, contentNavigation?.meta.pageId!, { reviewedNote: "Olmamış babus!" });
                await goToPage(contentNavigation?.meta.pageId!);
            } catch (error) {
                notification.error({
                    message: 'Hata',
                    description: 'Hata olustu. Lütfen tekrar deneyiniz.'
                })
            }

        }
    }

    const approvePageAndNext = async () => {
        if (versionId) {
            try {
                await API.CURRICULUM.COURSE_PAGE.approve(versionId, contentNavigation?.meta.unit.id!, contentNavigation?.meta.topic.id!, contentNavigation?.meta.pageId!);
                await goToPage(contentNavigation?.meta.pageId!);
                nextPage();
            } catch (error) {
                notification.error({
                    message: 'Hata',
                    description: 'Hata olustu. Lütfen tekrar deneyiniz.'
                })
            }

        }
    }

    const onPublish = async () => {
        if (versionId) {
            try {
                await API.CURRICULUM.COURSE_VERSION.publish(versionId);
                navigate('/admin-learning-content-versions');
            } catch (error) {
                notification.error({
                    message: 'Hata',
                    description: 'Hata olustu. Lütfen tekrar deneyiniz.'
                })
            }
        }
    }

    return (
        <ReviewLearningContentVersion
            loading={isLoading}
            contentNavigation={contentNavigation}
            onRejectPage={rejectPage}
            onApprovePageAndNext={approvePageAndNext}
            onNextPage={nextPage}
            onPrevPage={prevPage}
            onPublish={onPublish}
            onGoToPage={goToPage}
            onBack={() => { navigate("/admin-learning-content-versions") }}
            isAdminCdn={true}
        />
    );
}


AdminReviewLearningContentVersion.path = 'learning-content-versions/review-version';
AdminReviewLearningContentVersion.roles = [Role.ADMIN];