import { BellOutlined, LockOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Tabs } from "antd";
import type { TabsProps } from 'antd';
import { NotificationSetting, PasswordSetting, ProfileSetting } from "@admin/admin_components";
import { Role } from "@shared/constants";



const items: TabsProps['items'] = [
    {
        key: '1',
        label: "Profil ayarları",
        children: <ProfileSetting/>,
        icon: <UserOutlined />
    },
    {
        key: '2',
        label: "Bildirim ayarları",
        children: <NotificationSetting/>,
        icon: <BellOutlined />
    },
    {
        key: '3',
        label: "Parola ayarları",
        children: <PasswordSetting/>,
        icon: <LockOutlined />
    }
];

export const Setting = () => {
    return (
        <Card>
            <Tabs
                items={items}
            />
        </Card>
    )
}

Setting.path = 'setting';
Setting.title = 'Ayarlar';
Setting.group = '';
Setting.roles = [Role.ADMIN];
Setting.icon = <SettingOutlined size={20} /> 