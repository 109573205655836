import { useBrandContext } from '@shared/contexts';
import { BrandModel } from '@shared/models';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useBrandSearchParams = () => {
  const { brand, setBrand } = useBrandContext();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const brandId = searchParams.get('brandId');
    const brandName = searchParams.get('brandName');

    if (!brand) {
      if (brandId && brandName) {
        const brand: BrandModel = {
          id: brandId,
          name: brandName,
        };

        setBrand(brand);
      } else {
        navigate('/brands');
      }
    } else {
      if (!brandId || !brandName) {
        searchParams.set('brandId', brand.id || '');
        searchParams.set('brandName', brand.name || '');

        setSearchParams(searchParams);
      }
    }
  }, [brand, searchParams]);

  return { brand, setBrand };
};
