import 'dayjs/locale/tr';
import 'regenerator-runtime/runtime';
import { Notification } from '@shared/components';
import { BrandContextProvider, UserContextProvider } from '@shared/contexts';
import { App as AppAnt, ConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { AdminAppContent } from './AdminAppContent';
import { useTranslation } from 'react-i18next';
import { Locale } from 'antd/lib/locale';

export const App = () => {
  const [language, setLanguage] = useState<Locale>();
  const { i18n } = useTranslation();

  useEffect(() => {
    const antLang = i18n.getResourceBundle(
      i18n.language
        .split('')
        .filter((t) => t == t.toLowerCase())
        .join(''),
      'ant'
    );
    setLanguage(antLang);
  }, [i18n.language]);

  return (
    <ConfigProvider
      locale={language}
      theme={{
        token: {
          fontFamily: "'Helvetica Neue','arial'",
        },
      }}
    >
      <AppAnt>
        <Notification />
        <UserContextProvider>
          <BrandContextProvider>
            <AdminAppContent />
          </BrandContextProvider>
        </UserContextProvider>
      </AppAnt>
    </ConfigProvider>
  );
};
