import { BrandUserModel, CreateBrandUser, PaginateModel } from '@shared/models';
import { BrandAndBranchUserCard, BrandAndBranchUserForm } from '@shared/components';
import { BrandOrBranch } from '@shared/constants';
import { Pagination } from 'antd';

type CreateBrandUsersProps = {
  brandUsers?: PaginateModel<BrandUserModel>;
  enableProductTypes: string[];
  addBrandUser: (brandUser: CreateBrandUser) => void;
  onPaginationChange: (page: number) => void;
};

export function CreateBrandUsers({ brandUsers = {}, enableProductTypes = [], addBrandUser, onPaginationChange }: CreateBrandUsersProps) {
  return (
    <div className="grid grid-cols-3 items-start gap-8">
      <BrandAndBranchUserForm enableProductTypes={enableProductTypes} brandOrBranch={BrandOrBranch.BRAND} onSave={addBrandUser} />

      <div className="col-span-2">
        <label className="text-black/45 font-normal">Eklenen Kullanıcılar</label>

        {brandUsers.data?.length == 0 ? (
          <div className="border pt-9 border-dashed border-mid-gray rounded p-5 text-center">Lütfen marka yetkilisi ekleyiniz</div>
        ) : (
          <div className="border border-dashed border-mid-gray rounded p-5 grid grid-cols-2 gap-x-4 gap-y-8">
            {brandUsers.data?.map((user) => (
              <BrandAndBranchUserCard key={(Math.random() + 1).toString(36).substring(7)} user={user} onDelete={() => {}} onEdit={() => {}} />
            ))}
            <Pagination className="col-span-2 text-center" pageSize={brandUsers?.limit} total={brandUsers?.count} onChange={(page) => onPaginationChange(page)} />
          </div>
        )}
      </div>
    </div>
  );
}
