import { ApartmentOutlined } from "@ant-design/icons";
import { EducationLevelDefinitionComponent } from "@shared/components";
import { Role } from "@shared/constants";

export const EducationLevelDefinition = ()=>{

    return (<EducationLevelDefinitionComponent/>);
}

EducationLevelDefinition.path = 'education-level-definition';
EducationLevelDefinition.title = 'Kur Tanımları';
EducationLevelDefinition.group = 'definitions';
EducationLevelDefinition.roles = [Role.ADMIN];

