import { Avatar } from 'antd';
import { AvatarFromName } from '@shared/components';
import * as API from '@shared/api';

type BrandUserAvatarGroupType = {
  brandId: string;
  maxCount?: number;
};

export const BrandUserAvatarGroup = ({ brandId, maxCount = 2 }: BrandUserAvatarGroupType) => {
  const [result, loading] = API.BRAND.getBrandUsers({ init: true, initParams: { brandId } });
  return (
    <Avatar.Group maxCount={maxCount}>
      {result?.data?.map(({ id, name, email }) => (
        <AvatarFromName title={name!} subtitle={email!} key={id} />
      ))}
    </Avatar.Group>
  );
};
